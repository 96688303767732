import gql from 'graphql-tag'

export const GET_ALL_ZONES = gql`
  query {
    zones(limit: 10000) {
      businessUnitUuid
      businessUnit {
        businessUnitUuid
        label
      }
      zoneUuid
      label
    }
  }
`
export const GET_ALL_ZONES_FROM_BU = gql`
  query businessUnit($businessUnitUuid: GUID!) {
    businessUnit(businessUnitUuid: $businessUnitUuid) {
      serfStats {
        totalTarget
      }
      zones {
        zoneUuid
        businessUnitUuid
        label
        mslMeta {
          user {
            firstName
            lastName
            userUuid
          }
        }
        serfStats {
          totalSignedSerf
          totalTarget
        }
      }
    }
  }
`

export const GET_ZONES_FROM_BU = gql`
  query businessUnit($businessUnitUuid: GUID!) {
    businessUnit(businessUnitUuid: $businessUnitUuid) {
      zones {
        zoneUuid
        businessUnitUuid
        label
        mslMeta {
          user {
            firstName
            lastName
            userUuid
          }
        }
      }
    }
  }
`
