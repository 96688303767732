import gql from 'graphql-tag'

export const CREATE_APPLICATION = gql`
  mutation createApplication($application: ApplicationInput!) {
    createApplication(application: $application) {
      applicationUuid
      businessUnitUuids
      buUrls
      description
      hidden
      icon
      imageUrl
      place
      subtitle
      title
      url
      userTypes
    }
  }
`
