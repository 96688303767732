import gql from 'graphql-tag'

export const CREATE_USER = gql`
  mutation createUser($user: UserCreateInput!) {
    createUser(user: $user) {
      firstName
      lastName
      email
      rights
      userTypes
      userUuid
      mslMeta {
        mslMetaUuid
        zoneUuid
      }
    }
  }
`
