import gql from 'graphql-tag'

export const GET_MANAGERS = gql`
  query getManagers($userTypes: [UserType!]!) {
    searchUsers(query: { userTypes: $userTypes, limit: 1000 }) {
      rows {
        userUuid
        firstName
        lastName
      }
    }
  }
`
