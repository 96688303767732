import gql from 'graphql-tag'

import { BU_SERF_STATS } from './BuSerfStats'

export const GET_ALL_BUS = gql`
  query businessUnits {
    businessUnits(limit: 10000) {
      businessUnitUuid
      label
      externalId
      zones {
        zoneUuid
        label
      }
    }
  }
`

export const GET_BU_SERF_STATS = gql`
  query businessUnit($businessUnitUuid: GUID!) {
    businessUnit(businessUnitUuid: $businessUnitUuid) {
      serfStats {
        ...BusinessUnitSerfStats
      }
    }
  }
  ${BU_SERF_STATS}
`
