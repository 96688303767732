import gql from 'graphql-tag'

export const GET_HCPS_FOR_HISTORICAL_RECORDS = gql`
  query healthCareProfessionals($healthCareProfessionalUuid: [GUID]) {
    healthCareProfessionals(healthCareProfessionalUuid: $healthCareProfessionalUuid, limit: 1000) {
      healthCareProfessionalUuid
      firstName
      lastName
    }
  }
`

export const SEARCH_HCPS = gql`
  query searchHealthCareProfessionals($healthCareProfessionalUuids: [GUID], $search: String, $strict: Boolean) {
    searchHealthCareProfessionals(
      healthCareProfessionalUuids: $healthCareProfessionalUuids
      search: $search
      strict: $strict
    ) {
      healthCareProfessionalUuid
      firstName
      lastName
      veevaId
    }
  }
`
