import gql from 'graphql-tag'

export const GET_ALL_APPLICATION = gql`
  query allApplications {
    allApplications {
      applicationUuid
      businessUnitUuids
      buUrls
      description
      hidden
      icon
      imageUrl
      place
      subtitle
      title
      url
      userTypes
    }
  }
`
