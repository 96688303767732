<template>
  <user-picker
    v-model="currentUser"
    :clearable="clearable"
    :multiple="multiple"
    :label="label"
    :items="items"
    :dense="dense"
  />
</template>

<script>
import { SEARCH_USERS } from '@/graphql/User/GetUsers'

import UserPicker from './UserPicker'

export default {
  name: 'InternalUserPicker',
  components: { UserPicker },
  model: {
    prop: 'user',
    event: 'change'
  },
  props: {
    types: {
      type: [Array, String],
      required: false,
      default: null
    },
    user: {
      type: [String, Array],
      required: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.user
      },
      set(user) {
        this.$emit('change', user)
      }
    },
    items() {
      const items = this.$get(this.searchUsers, 'rows', [])

      return items.map(item => ({
        text: `${item.lastName} ${item.firstName}`,
        value: item.userUuid
      }))
    }
  },
  apollo: {
    searchUsers: {
      query: SEARCH_USERS,
      variables() {
        const userTypes =
          this.type === '*' || !this.types
            ? Object.values(this.$const.userType)
            : Array.isArray(this.types)
            ? this.types
            : [this.types]

        return {
          query: {
            userTypes
          }
        }
      }
    }
  }
}
</script>
