<template>
  <v-card class="DashboardApplication mb-4">
    <v-card-text>
      <v-row align="center" justify="space-between">
        <!-- Drag icon -->
        <v-col md="1" justify="center">
          <v-icon>mdi-drag-vertical-variant</v-icon>
        </v-col>

        <!-- Visibility -->
        <v-col md="1" justify="center">
          <v-dialog v-model="visibilityModal" max-width="300">
            <!-- Icon -->
            <template v-slot:activator="{ on, attrs }">
              <v-icon large @click.stop="on.click" v-bind="attrs">{{
                application.hidden ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              }}</v-icon>
            </template>

            <v-card>
              <v-card-title>{{ $t('manage-dashboard.visibility-dialog.title') }}</v-card-title>
              <v-card-text>
                {{
                  application.hidden
                    ? $t('manage-dashboard.visibility-dialog.show')
                    : $t('manage-dashboard.visibility-dialog.hide')
                }}
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="toggleVisibility" depressed primary color="primary">
                  {{ $t('manage-dashboard.visibility-dialog.submit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

        <!-- Title -->
        <v-col md="2">
          <span class="subtitle-2 ml-2">{{ application.title }}</span>
        </v-col>

        <!-- Roles -->
        <v-col md="2">
          <v-row align="center" justify="center">
            <v-col>
              {{ roles.slice(0, 4).join(' - ') }}
              <v-tooltip v-if="roles.length > 4" bottom transition="scroll-x-transition">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on">+{{ roles.length - 4 }}</v-chip>
                </template>
                <v-list-item v-for="role in roles" dense :key="role">
                  <span class="white--text">{{ role }}</span>
                </v-list-item>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>

        <v-col md="4" align="center">
          {{ readableBusinessUnits }}
        </v-col>

        <v-spacer />

        <v-col md="2" align="end">
          <v-dialog v-model="creationDialog" max-width="1110px" persistent>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-pencil</v-icon>
            </template>
            <v-card>
              <v-card-text>
                <application-form
                  :businessUnits="businessUnits"
                  :application="application"
                  @change="app => $emit('change', app)"
                  @close="creationDialog = false"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _findKey from 'lodash/findKey'

import ApplicationForm from './ApplicationForm'

export default {
  name: 'DashboardApplication',
  components: { ApplicationForm },
  model: {
    prop: 'application',
    event: 'change'
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    businessUnits: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      showAdditionalRoles: false,
      visibilityModal: false,
      creationDialog: false
    }
  },
  computed: {
    readableBusinessUnits() {
      const bus = this.application.businessUnitUuids

      if (!bus || (Array.isArray(bus) && bus.length <= 0)) {
        return '- -'
      }

      return bus
        .map(bu => this.businessUnits.find(b => b.businessUnitUuid === bu))
        .filter(Boolean)
        .map(bu => bu.label)
        .join('; ')
    },
    roles() {
      return this.application.userTypes.map(type => _findKey(this.$const.userType, t => t === type))
    }
  },
  methods: {
    toggleVisibility() {
      const app = Object.assign({}, this.application, { hidden: !this.application.hidden })

      this.$emit('change', app)
      this.$emit('persist', app.applicationUuid)
      this.visibilityModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.DashboardApplication {
  white-space: nowrap;
}

.DashboardApplication-menuItem {
  font-size: 0.7rem;
}
</style>
