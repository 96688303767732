<template>
  <v-container fluid>
    <div class="d-flex justify-space-between my-3">
      <v-btn color="primary" exact text :to="{ name: 'Home' }">
        <v-icon left>mdi-chevron-left</v-icon>{{ $t('manage-dashboard.back-button') }}
      </v-btn>
      <v-btn @click="creationDialog = true" depressed dark class="mr-3" color="blue">
        {{ $t('manage-dashboard.create-app.button') }}
      </v-btn>
      <div />
    </div>
    <!-- Query pending -->
    <v-banner :value="loading" dark rounded>
      <span class="subtitle-1">{{ $t('manage-dashboard.sync-pending') }}</span>

      <template v-slot:actions>
        <v-progress-circular color="blue" indeterminate class="mr-4" />
      </template>
    </v-banner>

    <div v-if="!loading">
      <v-expansion-panels accordion focusable>
        <draggable :list="applications" @sort="onSorted" class="Draggable-Wrapper">
          <dashboard-application
            v-for="(app, index) in applications"
            :business-units="businessUnits"
            :key="app.applicationUuid"
            v-model="applications[index]"
            @persist="persist"
            @stale="handleStale"
            @unstale="handleUnstale"
          />
        </draggable>
      </v-expansion-panels>
    </div>

    <v-dialog v-model="creationDialog" width="1100">
      <app-creation-dialog
        :business-units="businessUnits"
        @close="creationDialog = false"
        @create="createApp"
        :place="this.applications.length"
      />
    </v-dialog>

    <v-dialog v-model="createdDialog" max-width="390">
      <v-card>
        <v-card-title>
          {{ $t('manage-dashboard.create-app.confirmation-text') }}
        </v-card-title>
      </v-card>
    </v-dialog>

    <v-snackbar bottom centered :color="snackbar" :value="Boolean(snackbar)" @input="val => (snackbar = null)">
      {{ snackbar === 'success' ? $t`server-success` : $t`server-error` }}
    </v-snackbar>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import _cloneDeep from 'lodash/cloneDeep'

import DashboardApplication from '@/components/admin/DashboardApplication'
import AppCreationDialog from '@/components/admin/AppCreationDialog'
import { GET_ALL_APPLICATION } from '@/graphql/Application/AllApplication'
import { SET_APPLICATIONS } from '@/graphql/Application/SetApplications'
import { CREATE_APPLICATION } from '@/graphql/Application/CreateApplication'
import { GET_ALL_BUS } from '@/graphql/BusinessUnit/GetBus'

export default {
  name: 'ManageDashboard',
  components: { DashboardApplication, draggable, AppCreationDialog },
  data() {
    return {
      applications: [],
      staleApps: [],
      creationDialog: false,
      loading: true,
      snackbar: null,
      createdDialog: false
    }
  },
  apollo: {
    allApplications: {
      query: GET_ALL_APPLICATION,
      result(queryResult) {
        this.applications = queryResult.data.allApplications.sort((app1, app2) => app1.place - app2.place)
        this.loading = false
      }
    },
    businessUnits: {
      query: GET_ALL_BUS
    }
  },
  methods: {
    onSorted() {
      this.applications = this.applications.map((app, index) => Object.assign({}, app, { place: index }))

      this.persist()
    },
    async persist(applicationUuid = null) {
      const applications = this.applications
        .filter(app => (applicationUuid ? app.applicationUuid === applicationUuid : true))
        .map(app => {
          const _app = _cloneDeep(app)

          // Remove GQL properties and image related
          Object.keys(_app).forEach(property => {
            if (property.startsWith('__') || property.includes('image')) {
              delete _app[property]
            }
          })

          return _app
        })

      try {
        await this.$apollo.mutate({
          mutation: SET_APPLICATIONS,
          variables: {
            applications
          }
        })

        this.snackbar = 'success'
      } catch (error) {
        this.snackbar = 'error'
      }
    },
    async createApp(application) {
      try {
        const response = await this.$apollo.mutate({
          mutation: CREATE_APPLICATION,
          variables: { application }
        })

        const app = response.data.createApplication

        this.applications.push(app)

        this.createdDialog = true
      } catch (error) {
        console.error(error)

        this.snackbar = 'error'
      } finally {
        this.creationDialog = false
      }
    },
    handleStale(applicationUuid) {
      if (this.staleApps.includes(applicationUuid)) return

      this.staleApps.push(applicationUuid)
    },
    handleUnstale(applicationUuid) {
      this.staleApps = this.staleApps.filter(appUuid => appUuid !== applicationUuid)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.staleApps.length) {
      // eslint-disable-next-line
      const confirmation = window.confirm(this.$t('manage-dashboard.navigation-with-changes'))

      if (confirmation) {
        this.staleApps = []

        next()
      }
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
.Draggable-Wrapper {
  width: 100%;
}
</style>
