var templateObject$1 = Object.freeze(["type"]);
var templateObject = Object.freeze(["search-user"]);
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"UserList pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"toolbar mb-8 px-4 pt-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(templateObject)))]),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"clearable":"","outlined":"","dense":"","hide-details":"","append-icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.selectUserTypes,"label":_vm.$t(templateObject$1),"multiple":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.types),callback:function ($$v) {_vm.types=$$v},expression:"types"}})],1)],1)],1),_c('v-card',{staticClass:"pa-5 mb-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users.rows,"loading":_vm.loading,"server-items-length":_vm.users.count,"options":_vm.dataOptions,"items-per-page":10,"item-key":"userUuid","footer-props":{
        itemsPerPageOptions: [10, 15, 30]
      }},on:{"update:options":function($event){_vm.dataOptions=$event}},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
      var value = ref.value;
return [_c('span',{staticClass:"UserList-status",class:{ 'UserList-status--disable': !value }},[_vm._v(" "+_vm._s(_vm.$t(("userList.status." + value)))+" ")])]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return _vm._l((item.userTypes),function(userType){return _c('v-chip',{key:item.userUuid + userType,attrs:{"color":"admin-primary","dark":""}},[_vm._v(_vm._s(userType))])})}},{key:"item.businessUnit",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$get(item, 'mslMeta.businessUnit.label') || _vm.$get(item, 'mslMeta.zone.businessUnit.label') || _vm.$get(item, 'abmMeta.businessUnit.label'))+" ")]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"small",on:{"click":function($event){return _vm.updateUser(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.impersonificate",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.impersonificate(item)}}},[_vm._v("mdi-eye")])]}}])})],1),(_vm.hasRight(_vm.$const.right.createUser))?_c('v-btn',{staticClass:"add-button",attrs:{"color":"admin-primary","to":{ name: 'AdminCreateUser' },"fab":"","dark":"","elevation":"3"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }