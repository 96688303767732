<template>
  <v-container fluid>
    <v-row v-if="app">
      <!-- Illustration -->
      <v-col align-self="center" class="d-flex justify-center" cols="12" md="6">
        <v-card @click="triggerUpload" height="207" width="426">
          <v-img v-if="image" :src="image" max-height="207px" />
        </v-card>

        <input v-show="false" type="file" ref="fileInput" @change="handleImageInput" />
      </v-col>

      <!-- Details & Roles/BU -->
      <v-col cols="12" md="6">
        <v-row>
          <v-col>
            <div class="body-2">{{ $t('manage-dashboard.form.name') }}</div>
            <v-text-field
              v-model="app.title"
              counter="21"
              :data-vv-name="'name'"
              :data-vv-as="$t('manage-dashboard.form.name')"
              dense
              :error-messages="errors.collect('name')"
              full-width
              outlined
              :label="$t('manage-dashboard.form.name')"
              v-validate="'max:21'"
            />
          </v-col>
        </v-row>
        <!-- Links -->
        <v-row>
          <v-col v-if="!isInternalLink || allowFullEdit">
            <!-- Default link -->
            <div class="body-2">{{ $t('manage-dashboard.form.link') }}</div>
            <v-text-field
              v-model="app.url"
              :data-vv-name="'defaultUrl'"
              :data-vv-as="$t('manage-dashboard.form.defaultUrl')"
              dense
              :error-messages="errors.collect('defaultUrl')"
              full-width
              hide-details="auto"
              outlined
              :label="$t('manage-dashboard.form.link')"
              v-validate="'required|startWithHttp|url'"
            />
            <!-- BU related link -->
            <div v-if="!isInternalLink || allowFullEdit">
              <application-link-form
                :businessUnits="businessUnits"
                :selectedBus="app.businessUnitUuids"
                v-model="app.buUrls"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="body-2">{{ $t('manage-dashboard.form.description') }}</div>
            <v-textarea
              v-model="app.description"
              auto-grow
              data-e2e="input-description"
              dense
              full-width
              :label="$t('manage-dashboard.form.description')"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="body-2">{{ $t('manage-dashboard.form.roles') }}</div>
            <v-select
              v-model="app.userTypes"
              dense
              full-width
              hide-details="auto"
              :items="availableRoles"
              :label="$t('manage-dashboard.form.roles')"
              multiple
              outlined
              single-line
            />
          </v-col>
        </v-row>

        <v-row v-if="hasBURoles">
          <v-col>
            <div class="body-2">{{ $t('manage-dashboard.form.bus') }}</div>
            <v-select
              v-model="app.businessUnitUuids"
              dense
              full-width
              hide-details="auto"
              :items="availableBU"
              :label="$t('manage-dashboard.form.bus')"
              multiple
              outlined
              single-line
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="end" align="start">
      <v-btn depressed primary color="error" @click="resetAndClose" class="mr-3">{{
        $t('manage-dashboard.form.cancel')
      }}</v-btn>
      <v-btn depressed primary color="success" @click="submit">{{ $t('manage-dashboard.form.submit') }}</v-btn>
    </v-row>

    <v-snackbar bottom centered :color="snackbar" :value="Boolean(snackbar)" @input="snackbar = null">
      {{ snackbar === 'success' ? $t`server-success` : $t`server-error` }}
    </v-snackbar>
  </v-container>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'

import { SET_APPLICATIONS } from '@/graphql/Application/SetApplications'

import ApplicationLinkForm from './ApplicationLinkForm'

export default {
  name: 'ApplicationForm',

  components: { ApplicationLinkForm },

  $_veeValidate: {
    validator: 'new'
  },

  model: {
    prop: 'application',
    event: 'change'
  },

  props: {
    application: {
      type: Object,
      required: true
    },
    businessUnits: {
      type: Array,
      required: false,
      default: () => []
    },
    allowFullEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      app: null,
      file: null,
      uploadImage: null,
      availableRoles: Object.keys(this.$const.userType)
        .sort()
        .map(key => ({
          text: key,
          value: this.$const.userType[key]
        })),
      snackbar: null
    }
  },

  computed: {
    availableBU() {
      return this.businessUnits.map(bu => ({
        text: bu.label,
        value: bu.businessUnitUuid
      }))
    },
    hasBURoles() {
      return this.app.userTypes.some(role => [this.$const.userType.ABM, this.$const.userType.MSL].includes(role))
    },
    isInternalLink() {
      return Boolean(this.app.icon)
    },
    image() {
      if (this.uploadImage) {
        return this.uploadImage
      }

      return this.app.imageUrl
    }
  },

  methods: {
    async submit() {
      const validated = await this.$validator.validateAll()
      if (!validated) return

      const app = Object.assign(_cloneDeep(this.app), { file: this.uploadImage })

      this.$emit('change', app)

      // Disable the automatic persistance in case of full edit
      if (this.allowFullEdit) {
        this.$emit('submit')

        return
      }

      // Remove GQL properties and image related
      Object.keys(app).forEach(property => {
        if (property.startsWith('__') || property.includes('image')) {
          delete app[property]
        }
      })

      try {
        await this.$apollo.mutate({
          mutation: SET_APPLICATIONS,
          variables: {
            applications: [app]
          }
        })

        this.snackbar = 'success'

        setTimeout(() => {
          this.$emit('close')
        }, 2000)
      } catch (error) {
        this.snackbar = 'error'
      }
    },
    triggerUpload() {
      if (!this.$refs.fileInput) return

      this.$refs.fileInput.click()
    },
    async handleImageInput(event) {
      const file = this.$refs.fileInput.files[0]

      if (file instanceof File) {
        this.file = file
      }
    },
    resetAndClose() {
      this.$emit('close')

      this.setInitialApp()
    },
    setInitialApp() {
      // Remove the "hidden" property to prevent any unwanted update
      const app = _omit(_cloneDeep(this.application), 'hidden')

      if (!Array.isArray(app.businessUnitUuids)) {
        app.businessUnitUuids = []
      }

      if (!app.buUrls) {
        app.buUrls = {}
      }

      this.app = app
    }
  },

  mounted() {
    this.setInitialApp()
  },

  watch: {
    file(val) {
      if (!val || !(val instanceof File)) return

      const reader = new FileReader()

      reader.readAsDataURL(val)
      reader.onloadend = () => {
        this.uploadImage = reader.result
      }
    },
    'app.businessUnitUuids'(val) {
      // Remove links associated to non-choosed BU
      Object.keys(this.app.buUrls).forEach(bu => {
        if (!val.includes(bu)) {
          delete this.app.buUrls[bu]
        }
      })
    }
  }
}
</script>
