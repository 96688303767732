var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{attrs:{"label":"Date de début"},model:{value:(_vm.gathering.beginDate),callback:function ($$v) {_vm.$set(_vm.gathering, "beginDate", $$v)},expression:"gathering.beginDate"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{attrs:{"label":"Date de fin"},model:{value:(_vm.gathering.endDate),callback:function ($$v) {_vm.$set(_vm.gathering, "endDate", $$v)},expression:"gathering.endDate"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('mybb-select',{attrs:{"items":_vm.eventTypeItems,"label":"Type d'événement"},model:{value:(_vm.eventType),callback:function ($$v) {_vm.eventType=$$v},expression:"eventType"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('mybb-select',{attrs:{"items":_vm.availablesVersions,"label":"Version de catégorie"},model:{value:(_vm.categoryVersion),callback:function ($$v) {_vm.categoryVersion=$$v},expression:"categoryVersion"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('mybb-text-field',{attrs:{"label":"Recherche"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"my-2"},[_c('mybb-text',[_vm._v(" Type d'événement : "),_c('mybb-text',{attrs:{"weight":"bold"}},[_vm._v(_vm._s(_vm.libraryEventType))])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"items":_vm.categories || [],"headers":_vm.headers,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":value,"dark":""}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.eventTypes",fn:function(ref){
var value = ref.value;
return _vm._l((value || []),function(type){return _c('mybb-text',{key:type,staticClass:"my-1",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(type)+" ")])})}},{key:"item.tags",fn:function(ref){
var value = ref.value;
return _vm._l((value || []),function(tag){return _c('mybb-text',{key:tag,staticClass:"my-1",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(tag)+" ")])})}},{key:"item.modules",fn:function(ref){
var value = ref.value;
return _vm._l((value || []),function(module){return _c('mybb-text',{key:module,staticClass:"my-1",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(module)+" ")])})}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }