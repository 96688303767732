<template>
  <div class="ActivityOrators">
    <v-row class="pa-0 ma-0 mb-6" align="center">
      <v-col class="py-0 pl-0" cols="6" md="6">
        <participant-picker
          v-model="orator"
          :cypress="$cy.activity.form.orator"
          :label="t('orator')"
          ref="hcpPicker"
          :tags="tags"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col class="pa-0" cols="1" md="1">
        <mybb-btn
          class="border-radius-10-forced elevation-2"
          v-cy="$cy.activity.form.oratorAdd"
          @click="addOrator"
          icon
          inner-icon="mdi-plus"
          background="white"
          color="mybb-primary-lighten1"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>

    <div>
      <mybb-text size="12" class="mb-2 mybb-grey-lighten1--text">{{ t('listTitle') }}</mybb-text>
      <div class="ActivityOrator-List py-3 px-4">
        <v-chip
          v-for="participantUuid of currentOrators"
          v-cy="$cy.activity.form.oratorItem(participantNameMapping[participantUuid])"
          :key="participantUuid"
          class="mr-2"
          text-color="white"
          color="mybb-grey-lighten1"
          pill
          @click="removeHcp = participantUuid"
          :disabled="isDisabled"
        >
          <v-icon class="ml-1" color="white" size="16" left>mdi-close</v-icon>
          {{ participantNameMapping[participantUuid] }}
        </v-chip>
      </div>
    </div>

    <modal v-model="modal" :title="t('oratorModalTitle')">
      <template v-slot:actions>
        <v-row class="ma-0 pa-0" justify="center">
          <mybb-btn class="mr-10" color="mybb-grey" @click="modal = false">
            {{ t('oratorModalCancel') }}
          </mybb-btn>

          <mybb-btn color="mybb-error" @click="removeSelectedHcp" :disabled="isDisabled">
            {{ t('oratorModalConfirm') }}
          </mybb-btn>
        </v-row>
      </template>
    </modal>
  </div>
</template>

<script>
import Categories from 'mybb-categories'

import { PARTICIPANTS_FOR_GATHERING__USER } from '@/graphql/Participant'

import ParticipantPicker from '../user/ParticipantPicker'
import Modal from '../Modal'

export default {
  name: 'ActivityOrators',
  components: { ParticipantPicker, Modal },
  model: {
    prop: 'orators',
    event: 'change'
  },
  props: {
    orators: {
      type: Array,
      required: false,
      default: () => [],
      validator(prop) {
        return Array.isArray(prop) && prop.every(item => item.hasOwnProperty('participantUuid'))
      }
    },
    gatheringUuid: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orator: null,
      removeHcp: null
    }
  },
  computed: {
    currentOrators: {
      get() {
        return this.orators.map(orator => orator.participantUuid)
      },
      set(orator) {
        let orators = this.orators.map(orator => orator.participantUuid)

        if (orators.includes(orator)) {
          orators = orators.filter(o => o !== orator)
        } else {
          orators.push(orator)
        }

        this.$emit('change', orators.map(participantUuid => ({ participantUuid })))
      }
    },
    modal: {
      get() {
        return this.removeHcp !== null
      },
      set(val) {
        if (!val) {
          this.removeHcp = null
        } else {
          this.removeHcp = val
        }
      }
    },
    picker() {
      return this.$refs.hcpPicker
    },
    participantNameMapping() {
      return (this.participants || []).reduce(
        (acc, participant) => ({
          ...acc,
          [participant.participantUuid]: `${participant.firstName} ${participant.lastName}`
        }),
        {}
      )
    },
    tags() {
      return [Categories.constants.Tag.Orator]
    }
  },
  apollo: {
    participants: {
      query: PARTICIPANTS_FOR_GATHERING__USER,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid
        }
      },
      update({ participantsForGathering }) {
        return participantsForGathering
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.activity.form.${key}`, params)
    },
    addOrator() {
      if (!this.orator) return

      this.currentOrators = this.orator
      this.orator = null
      this.picker.clearSearch()
    },
    removeSelectedHcp() {
      this.currentOrators = this.removeHcp
      this.removeHcp = null
    }
  }
}
</script>

<style lang="scss" scoped>
.ActivityOrators {
  width: 100%;
}

.ActivityOrator-List {
  border-radius: 8px;
  border: 1px solid var(--v-mybb-grey-lighten1);
  min-height: 80px;
}
</style>
