<template>
  <form>
    <User v-model="form" mode="edit" />
  </form>
</template>

<script>
import _get from 'lodash/get'

import User from '@/components/admin/User.vue'
import { GET_USER } from '@/graphql/User/GetUser'
import { GET_ALL_ZONES } from '@/graphql/Zone/GetZones'
import { GET_MANAGERS } from '@/graphql/User/SearchUsers'

export default {
  components: {
    User
  },

  data() {
    return {
      user: {}
    }
  },

  apollo: {
    managers: {
      query: GET_MANAGERS,
      variables() {
        return {
          userTypes: [
            this.$const.userType.ABM_MANAGER,
            this.$const.userType.MSL_MANAGER,
            this.$const.userType.DM,
            this.$const.userType.CP
          ]
        }
      },
      update: ({ searchUsers }) => searchUsers.rows
    },
    user: {
      query: GET_USER,
      variables() {
        return {
          uuid: this.$route.params.uuid
        }
      },
      update: ({ user }) => ({
        ...user,
        managerUuid: _get(user, 'manager.userUuid'),
        mslZoneUuid: _get(user, 'mslMeta.zoneUuid'),
        businessUnitUuid: _get(user, 'abmMeta.businessUnitUuid')
      })
    },
    zones: {
      query: GET_ALL_ZONES
    }
  },

  computed: {
    form: {
      get() {
        return {
          managers: this.managers,
          user: this.user,
          zones: this.zones
        }
      },
      set(value) {
        if (!value || (value && !value.user)) return

        this.user = value.user
      }
    }
  }
}
</script>

<style lang="scss">
.User {
  max-width: 1000px;
}
</style>
