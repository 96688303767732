<template>
  <user-picker
    v-model="currentUser"
    :clearable="clearable"
    :multiple="multiple"
    :label="label"
    :items="items"
    :loading="$apollo.queries.healthCareProfessionals.loading"
    :search="search"
    :dense="dense"
    @search="value => (search = value)"
    :filter="onFilter"
    :disabled="disabled"
  />
</template>

<script>
import { SEARCH_HCPS } from '@/graphql/Hcp/GetHcps'

import UserPicker from './UserPicker'

export default {
  name: 'HealthCareProfessionalPicker',
  components: { UserPicker },
  model: {
    prop: 'user',
    event: 'change'
  },
  props: {
    from: {
      type: Array,
      required: false,
      default: () => [],
      validator(prop) {
        return Array.isArray(prop) && prop.every(item => typeof item === 'string')
      }
    },
    user: {
      type: [String, Array],
      required: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    strict: {
      type: Boolean,
      required: false,
      default: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.user
      },
      set(user) {
        this.$emit('change', user)
      }
    },
    items() {
      // Don't show autocomplete result if there is no search
      if (!this.search) return []

      return (this.healthCareProfessionals || []).map(item => ({
        text: `${item.lastName} ${item.firstName} - ${item.veevaId}`,
        value: item.healthCareProfessionalUuid
      }))
    }
  },
  apollo: {
    healthCareProfessionals: {
      query: SEARCH_HCPS,
      variables() {
        const healthCareProfessionalUuids = Array.isArray(this.from) ? Array.from(this.from) : []

        if (healthCareProfessionalUuids.length > 0) {
          if (Array.isArray(this.currentUser)) {
            healthCareProfessionalUuids.push(...this.currentUser)
          } else {
            healthCareProfessionalUuids.push(this.currentUser)
          }
        }

        return {
          search: this.search,
          healthCareProfessionalUuids: healthCareProfessionalUuids.filter(Boolean),
          strict: this.strict
        }
      },
      debounce: 250,
      update({ searchHealthCareProfessionals }) {
        return searchHealthCareProfessionals
      },
      skip() {
        return !Array.isArray(this.from) && !this.search
      }
    }
  },
  methods: {
    onFilter(item, queryText, itemText) {
      const tokens = queryText.split(' ')

      return tokens.every(token => itemText.toLowerCase().includes(token.toLowerCase()))
    },
    clearSearch() {
      this.search = ''
    }
  },
  watch: {
    search(val, old) {
      if (val !== old && this.currentUser) {
        this.currentUser = null
      }
    }
  }
}
</script>
