<template>
  <mybb-autocomplete
    v-model="currentUser"
    v-cy="cypress"
    :disabled="disabled"
    :clearable="clearable"
    :multiple="multiple"
    :label="label"
    :items="items"
    :loading="loading"
    @update:search-input="onSearch"
    :search-input="search"
    :chips="multiple"
    :filter="filter"
    :append-icon="hasAppendIcon ? 'mdi-menu-down' : null"
    :icon="icon"
    hide-no-data
    editable
    outlined
    hide-details
    background-color="white"
  />
</template>

<script>
export default {
  name: 'UserPicker',
  model: {
    prop: 'user',
    event: 'change'
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
      validator(prop) {
        if (!prop) return true

        return Array.isArray(prop) && prop.every(item => item.text && item.value)
      }
    },
    user: {
      type: [String, Array],
      required: false,
      default: null
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: String,
      required: false,
      default: ''
    },
    filter: {
      type: Function,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    hasAppendIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    cypress: {
      type: String
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.user
      },
      set(user) {
        const _user = this.multiple && Array.isArray(user) ? user.filter(Boolean) : user

        this.$emit('change', _user)
      }
    }
  },
  methods: {
    onSearch(search) {
      if (this.items.some(item => item.text === search)) return

      this.$emit('search', search)
    }
  }
}
</script>
