<template>
  <user-picker
    v-model="currentUser"
    :clearable="clearable"
    :cypress="cypress"
    :multiple="multiple"
    :label="label"
    :items="items"
    :loading="$apollo.queries.participants.loading"
    :search="search"
    @search="value => (search = value)"
    :filter="onFilter"
    :disabled="disabled"
    :icon="icon"
    :has-append-icon="hasAppendIcon"
  />
</template>

<script>
import { PARTICIPANTS_PICKER_SEARCH } from '@/graphql/Participant'

import UserPicker from './UserPicker'

export default {
  name: 'ParticipantPicker',
  components: { UserPicker },
  model: {
    prop: 'user',
    event: 'change'
  },
  props: {
    user: {
      type: [String, Array]
    },
    clearable: {
      type: Boolean
    },
    multiple: {
      type: Boolean
    },
    label: {
      type: String
    },
    strict: {
      type: Boolean,
      default: true
    },
    autocompleteEmptySearch: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    icon: {
      type: String
    },
    hasAppendIcon: {
      type: Boolean
    },
    tags: {
      type: Array
    },
    cypress: {
      type: String
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.user
      },
      set(user) {
        this.$emit('change', user)
      }
    },
    items() {
      // Don't show autocomplete result if there is no search
      if (!this.search && !this.autocompleteEmptySearch) return []

      return (this.participants || []).map(participant => {
        let text = `${participant.firstName} ${participant.lastName}`

        if (participant.healthCareProfessional) {
          text += ` - ${participant.healthCareProfessional.veevaId}`
        }

        return {
          text,
          value: participant.participantUuid
        }
      })
    }
  },
  apollo: {
    participants: {
      query: PARTICIPANTS_PICKER_SEARCH,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid,
          search: this.search,
          tags: Array.isArray(this.tags) ? this.tags : null
        }
      },
      debounce: 250,
      update({ searchParticipants }) {
        return searchParticipants
      },
      skip() {
        return !this.search && !this.autocompleteEmptySearch
      }
    }
  },
  methods: {
    onFilter(item, queryText, itemText) {
      const tokens = queryText.split(' ')

      return tokens.every(token => itemText.toLowerCase().includes(token.toLowerCase()))
    },
    clearSearch() {
      this.search = ''
    }
  },
  watch: {
    search(val, old) {
      if (val !== old && this.currentUser) {
        this.currentUser = null
      }
    }
  }
}
</script>
