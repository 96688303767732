<template>
  <v-container class="User flex-grow-0">
    <v-card class="pa-5">
      <v-row>
        <v-col cols="6" class="display-1 mt-2 mb-4 admin-primary--text">
          {{ $t`user` }}
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn :to="{ name: 'AdminUserList' }" text color="admin-primary" class="subtitle-1 text-lowercase">
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t`back-to-list` }}
          </v-btn>
        </v-col>
      </v-row>

      <v-alert v-show="error" type="error" v-html="error" />

      <v-row class="body-2">
        <v-col md="2">
          <div class="body-2">{{ $t`profile` }}*</div>
          <v-select
            v-model="userProfile"
            v-validate="'required'"
            :data-vv-as="$t('profile')"
            data-vv-name="userProfile"
            dense
            :error-messages="errors.collect('userProfile')"
            hide-details="auto"
            :items="profiles"
            outlined
            required
          />
        </v-col>
        <v-col md="4">
          <div class="body-2" :class="{ 'grey--text': !userProfile }">{{ $t`type` }}*</div>
          <v-select
            v-model="userTypes"
            :items="selectUserTypes"
            v-validate="'required'"
            :data-vv-as="$t('type')"
            data-vv-name="user.userTypes"
            dense
            :disabled="!userProfile"
            :error-messages="errors.collect('user.userTypes')"
            hide-details="auto"
            multiple
            outlined
            required
          />
        </v-col>
        <v-col v-if="mode === 'edit'" md="6" class="text-right grey--text">
          <div>{{ $t`updated-at` }} {{ formatedUpdateDate }}</div>
          <div>
            <v-checkbox
              :value="form.user.enabled"
              @click="modalToggleUser = true"
              color="admin-primary"
              :label="$t('adminUser.disableUserLabel')"
              hide-details
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <div class="body-2">{{ $t`firstName` }}*</div>
          <v-text-field
            v-model="firstName"
            v-validate="'required'"
            :data-vv-as="$t`firstName`"
            data-vv-name="firstName"
            :error-messages="errors.collect('firstName')"
            hide-details="auto"
            outlined
            dense
            required
          />
        </v-col>
        <v-col md="6">
          <div class="body-2">{{ $t`lastName` }}*</div>
          <v-text-field
            v-model="lastName"
            v-validate="'required'"
            :data-vv-name="$t`lastName`"
            :error-messages="errors.collect($t`lastName`)"
            hide-details="auto"
            outlined
            dense
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <div class="body-2">{{ $t`email` }}*</div>
          <v-text-field
            v-model="email"
            v-validate="'required|email'"
            :data-vv-name="$t`email`"
            :error-messages="errors.collect($t`email`)"
            hide-details="auto"
            outlined
            dense
            required
          />
        </v-col>

        <v-col md="6" v-if="!shouldHidePassword">
          <div class="body-2">{{ showChangePassword ? $t`password` : '&nbsp;' }}</div>
          <v-btn color="admin-primary" dark depressed v-show="!showChangePassword" @click="showChangePassword = true">{{
            $t`change-password`
          }}</v-btn>

          <v-text-field
            v-model="password"
            v-show="showChangePassword"
            v-validate="undefined"
            :data-vv-name="$t`password`"
            :error-messages="errors.collect($t`password`)"
            hide-details="auto"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @click:clear="clearPassword"
            clearable
            outlined
            dense
          />
        </v-col>

        <v-col md="6" v-else-if="shouldHidePassword && mode === 'edit'">
          <div class="body-2">{{ $t`password` }}</div>
          <v-dialog max-width="490" no-click-animation persistent v-model="resetPasswordModal">
            <template v-slot:activator="{ on }">
              <v-btn color="admin-primary" dark depressed v-on="on">{{ $t('reset-password.button') }}</v-btn>
            </template>

            <v-card>
              <v-card-title class="title">{{ $t('reset-password.dialog.title') }}</v-card-title>
              <v-card-text>
                <div class="text-center mt-4">
                  <v-btn color="error" depressed @click="resetPasswordModal = null">{{
                    $t('reset-password.dialog.cancel')
                  }}</v-btn>

                  <v-btn color="success" depressed @click="resetPassword" class="ml-4">
                    {{ $t('reset-password.dialog.confirm') }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="body-2">{{ $t`phone` }}</div>
          <v-text-field
            v-model="phone"
            :data-vv-name="$t`phone`"
            :error-messages="errors.collect($t`phone`)"
            hide-details="auto"
            outlined
            dense
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="mustHaveManager" md="6">
          <div class="body-2">{{ $t`manager` }}*</div>
          <v-select
            v-model="managerUuid"
            v-validate="'required'"
            :data-vv-name="$t`manager`"
            :error-messages="errors.collect($t`manager`)"
            :items="managersFormated"
            hide-details="auto"
            outlined
            dense
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="hasBusinessUnit" md="6">
          <div class="body-2">{{ $t`bu` }}*</div>
          <v-select
            v-model="businessUnitUuid"
            v-validate="hasBusinessUnit ? 'required' : undefined"
            :data-vv-name="$t`bu`"
            :error-messages="errors.collect($t`bu`)"
            :items="bus"
          />
        </v-col>
        <v-col v-if="userTypes && userTypes.includes(CONST.userType.MSL)" md="6">
          <div class="body-2">{{ $t`zone` }}*</div>
          <v-select
            v-model="mslZoneUuid"
            v-validate="userTypes && userTypes.includes(CONST.userType.MSL) ? 'required' : undefined"
            :data-vv-name="$t`zone`"
            :error-messages="errors.collect($t`zone`)"
            :items="selectZones"
            hide-details="auto"
            outlined
            dense
            required
          />
        </v-col>
        <v-col v-if="hasZone" md="6">
          <div class="body-2">{{ $t`zone` }}*</div>
          <v-select
            v-model="businessUnitUuid"
            v-validate="userTypes && userTypes.includes(CONST.userType.ABM) ? 'required' : undefined"
            :data-vv-name="$t`bu`"
            :error-messages="errors.collect($t`bu`)"
            :items="bus"
            hide-details="auto"
            outlined
            dense
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12" class="pb-0">
          <div class="body-2">{{ $t`permissions` }}</div>
          <v-select v-model="rights" :items="selectPermissions" hide-details="auto" multiple outlined dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">{{ item.text }}</v-chip>
              <span v-if="index === 3" class="grey--text caption">(+{{ user.rights.length - 3 }} others)</span>
            </template>
          </v-select>
          <div class="body-2 grey--text">{{ $t`user-rights-hint` }}</div>
        </v-col>
      </v-row>

      <v-row v-show="$get(user, 'userTypes.length')">
        <v-col md="12" class="pt-1 pb-0 mt-5">
          <div class="body-2">{{ $t`inherited-permissions` }}</div>
          <v-chip v-for="right in inheritedRights" :key="right" class="mr-2 mt-1" small disabled>
            {{ right }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col md="6" class="grey--text">
          {{ status }}
        </v-col>
        <v-col md="6" class="text-right">
          <v-btn :to="{ name: 'AdminUserList' }" depressed class="mb-2">{{ $t`cancel` }}</v-btn>
          <v-btn depressed class="ml-2 mb-2 success" @click="mode === 'edit' ? updateUser() : prevCreateUser()">{{
            $t`save`
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog max-width="490" no-click-animation persistent :value="confirmModal">
      <v-card>
        <v-card-title class="title">{{ textConfirmModal }}</v-card-title>
        <v-card-text>
          <div class="text-center mt-4">
            <v-btn color="primary" depressed :to="{ name: 'Admin' }">{{ $t('adminUser.backToListButtonName') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <base-confirm-modal
      @cancel="modalConfirmEmail = false"
      @confirm="confirmEmail"
      :dialog="modalConfirmEmail"
      max-width="490"
      no-click-animation
      persistent
      :title="$t('adminUser.confirmEmail.title')"
    >
      <template v-slot:text>
        <v-text-field class="mt-4" label="email" :value="user.email" readonly outlined />
      </template>
    </base-confirm-modal>
    <ConfirmModal
      v-model="modalToggleUser"
      @confirm="toggleUser"
      @cancel="modalToggleUser = false"
      persistent
      :title="modalToggleUserTitle"
    />
  </v-container>
</template>

<script>
import _get from 'lodash/get'
import _set from 'lodash/set'
import moment from 'moment'

import CONST from '@/const/shared'
import { CREATE_USER } from '@/graphql/User/CreateUser'
import { UPDATE_USER } from '@/graphql/User/UpdateUser'
import { CHANGE_USER_STATUS } from '@/graphql/User/ChangeUserStatus'
import { GENERATE_RESET_PASSWORD } from '@/graphql/User/ResetPassword'
import ConfirmModal from '@/components/base/ConfirmModal'

export default {
  name: 'User',

  components: { ConfirmModal },

  inject: ['$validator'],

  model: {
    prop: 'form'
  },

  props: {
    form: Object,
    mode: String
  },

  data() {
    return {
      changeStatus: false,
      confirmModal: false,
      CONST,
      error: undefined,
      modalConfirmEmail: false,
      modalToggleUser: false,
      showChangePassword: this.mode === 'create',
      showPassword: false,
      status: '',
      textConfirmModal: '',
      resetPasswordModal: null,
      localUserProfile: null
    }
  },

  computed: {
    userProfile: {
      get() {
        if (!Array.isArray(this.user.userTypes)) {
          return this.localUserProfile
        }

        return this.user.userTypes.includes(CONST.userType.MCI) ? CONST.userProfile.AGENCE : CONST.userProfile.BIOGEN
      },
      set(value) {
        this.localUserProfile = value
      }
    },
    userTypes: {
      get() {
        return this.user.userTypes
      },
      set(value) {
        this.setUserProperty('userTypes', value)
      }
    },
    firstName: {
      get() {
        return this.user.firstName
      },
      set(value) {
        this.setUserProperty('firstName', value)
      }
    },
    lastName: {
      get() {
        return this.user.lastName
      },
      set(value) {
        this.setUserProperty('lastName', value)
      }
    },
    email: {
      get() {
        return this.user.email
      },
      set(value) {
        this.setUserProperty('email', value)
      }
    },
    password: {
      get() {
        return this.user.password
      },
      set(value) {
        this.setUserProperty('password', value)
      }
    },
    phone: {
      get() {
        return this.user.phone
      },
      set(value) {
        this.setUserProperty('phone', value)
      }
    },
    managerUuid: {
      get() {
        return this.user.managerUuid
      },
      set(value) {
        this.setUserProperty('managerUuid', value)
      }
    },
    rights: {
      get() {
        return this.user.rights
      },
      set(value) {
        this.setUserProperty('rights', value)
      }
    },
    mslZoneUuid: {
      get() {
        return this.user.mslZoneUuid
      },
      set(mslZoneUuid) {
        this.setUserProperty('mslZoneUuid', mslZoneUuid)
      }
    },
    businessUnitUuid: {
      get() {
        return this.user.businessUnitUuid
      },
      set(businessUnitUuid) {
        const partial = { businessUnitUuid }

        if (
          this.userTypes.includes(this.$const.userType.MSL) &&
          this.mslZoneUuid &&
          this.zones &&
          this.zones.length > 0
        ) {
          const zone = this.zones.find(zone => zone.zoneUuid === this.mslZoneUuid)

          if (zone && zone.businessUnitUuid !== businessUnitUuid) {
            partial.mslZoneUuid = null
          }
        }

        this.setUserPartial(partial)
      }
    },
    mustHaveManager() {
      return (
        this.user.userTypes &&
        (this.user.userTypes.includes(CONST.userType.MSL) || this.user.userTypes.includes(CONST.userType.ABM))
      )
    },
    bus() {
      return this.$store.state.bus.map(bu => ({ text: bu.label, value: bu.businessUnitUuid }))
    },
    formatedUpdateDate() {
      if (!this.user || !this.user.updatedAt) {
        return ''
      }

      return moment(this.user.updatedAt).format('DD/MM/YYYY')
    },
    fullName() {
      return `${this.user.firstName} ${this.user.lastName}`
    },
    inheritedRights() {
      if (!Array.isArray(this.userTypes)) return []

      const rights = new Set()

      for (const type of this.userTypes) {
        CONST.userTypeRights[type].forEach(right => rights.add(right))
      }

      return Array.from(rights).sort((a, b) => a.localeCompare(b))
    },
    managersFormated() {
      if (!this.form || !this.form.managers) return []

      return this.form.managers.map(manager => ({
        text: `${manager.firstName} ${manager.lastName}`,
        value: manager.userUuid
      }))
    },
    modalToggleUserTitle() {
      return this.form.user.enabled
        ? this.$t('adminUser.modalToggleUser.disable.title')
        : this.$t('adminUser.modalToggleUser.enable.title')
    },
    profiles() {
      return Object.values(CONST.userProfile)
    },
    selectPermissions() {
      return Object.keys(CONST.right)
        .map(key => ({
          text: key,
          value: CONST.right[key]
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
        .filter(el => !this.inheritedRights.includes(el.value))
    },
    selectUserTypes() {
      return Object.keys(CONST.userType)
        .filter(key => {
          if (this.userProfile === CONST.userProfile.AGENCE) {
            return CONST.userType[key] === CONST.userType.MCI
          }

          return CONST.userType[key] !== CONST.userType.MCI
        })
        .map(key => ({
          text: key,
          value: CONST.userType[key]
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    selectZones() {
      if (!this.zones) {
        return []
      }

      return this.zones
        .filter(zone => zone.businessUnitUuid === this.businessUnitUuid)
        .map(zone => ({
          text: zone.label,
          value: zone.zoneUuid
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    user() {
      return this.form ? this.form.user : {}
    },
    zones() {
      if (!this.form) {
        return []
      }
      return this.form.zones
    },
    shouldHidePassword() {
      return !this.userProfile || this.userProfile === CONST.userProfile.AGENCE
    },
    hasBusinessUnit() {
      const { ABM, MSL, ABM_MANAGER, MSL_MANAGER, DM } = this.$const.userType

      return this.userTypes && [ABM, MSL, ABM_MANAGER, MSL_MANAGER, DM].some(role => this.userTypes.includes(role))
    },
    hasZone() {
      const { MSL } = this.$const.userType

      return this.userTypes && this.userTypes.includes(MSL)
    }
  },

  methods: {
    setUserPartial(partial) {
      this.$emit('input', { ...this.form, user: { ...this.form.user, ...partial } })
    },
    setUserProperty(property, value) {
      const form = JSON.parse(JSON.stringify(this.form))

      _set(form, ['user', property], value)

      this.$emit('input', form)
    },
    clearPassword() {
      if (this.mode === 'edit') {
        this.showChangePassword = false
      }
    },
    confirmEmail() {
      this.modalConfirmEmail = false
      this.createUser()
    },
    async createUser() {
      const user = {
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        managerUuid: this.user.managerUuid,
        mslZoneUuid: this.user.mslZoneUuid,
        password: this.user.password,
        rights: this.user.rights,
        userTypes: this.user.userTypes,
        phone: this.user.phone
      }

      if (this.user.businessUnitUuid) {
        user.businessUnitUuid = this.user.businessUnitUuid
      }

      try {
        await this.$apollo.mutate({
          mutation: CREATE_USER,
          variables: { user }
        })

        this.textConfirmModal = this.$t('adminUser.createUserModal.title', { value: this.fullName })
        this.confirmModal = true
      } catch (e) {
        this.error = this.$t`error:` + ` ${this.getGraphQLErrorMessage(e)}`
      }
    },
    getGraphQLErrorMessage(e) {
      const lastGraphQLError = e.graphQLErrors.pop()
      const innerErrors = _get(lastGraphQLError, 'extensions.exception.errors', [])

      let message = `${lastGraphQLError.message}`
      if (innerErrors.length > 0) {
        message += `<ul>${innerErrors.map(e => `<li>${e.message}</li>`)}</ul>`
      }

      return message
    },
    async prevCreateUser() {
      this.error = ''

      if (!(await this.$validator.validateAll())) {
        return
      }

      if (this.user.userTypes.includes(this.$const.userType.MCI)) {
        this.modalConfirmEmail = true
      } else {
        this.createUser()
      }
    },
    toggleUser() {
      this.form.user.enabled = !this.form.user.enabled
      this.changeStatus = !this.changeStatus
    },
    async updateUser() {
      this.error = ''
      this.status = ''

      if (!(await this.$validator.validateAll())) {
        return
      }

      const user = {
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        managerUuid: this.user.managerUuid,
        phone: this.user.phone,
        rights: this.user.rights,
        userTypes: this.user.userTypes,
        userUuid: this.$route.params.uuid
      }

      if (this.user.businessUnitUuid) {
        user.businessUnitUuid = this.user.businessUnitUuid
      }

      if (this.user.password && this.user.password.trim() !== '') {
        user.password = this.user.password
      }

      if (
        this.user.userTypes &&
        (this.user.userTypes.includes(CONST.userType.MSL) || this.user.userTypes.includes(CONST.userType.MSL_MANAGER))
      ) {
        user.mslZoneUuid = this.user.mslZoneUuid
      }

      if (this.changeStatus) {
        await this.$apollo.mutate({
          mutation: CHANGE_USER_STATUS,
          variables: { userUuid: this.form.user.userUuid, enabled: this.form.user.enabled }
        })
        this.changeStatus = false
      }

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: { user },
          update: (store, { data: { updateUser } }) => {
            this.user.updatedAt = updateUser.updatedAt
          }
        })

        this.textConfirmModal = this.$t('adminUser.updateUserModal.title', { value: this.fullName })
        this.confirmModal = true
      } catch (e) {
        this.status = this.$t`error`
        this.error = this.$t`error` + ` ${this.getGraphQLErrorMessage(e)}`
      }
    },
    async resetPassword() {
      await this.$apollo.mutate({
        mutation: GENERATE_RESET_PASSWORD,
        variables: {
          sendEmail: true,
          userUuid: this.user.userUuid
        }
      })

      this.resetPasswordModal = false
    }
  },

  watch: {
    localUserProfile(value, oldValue) {
      if (!this.userTypes || !oldValue) return

      this.userTypes = null
    }
  }
}
</script>

<style lang="scss">
.User {
  .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
