import gql from 'graphql-tag'

export const LIST_USERS = gql`
  query listUsers($userUuid: [GUID!]!) {
    listUsers(userUuid: $userUuid) {
      userUuid
      lastName
      firstName
    }
  }
`

export const GET_USERS_TYPE = gql`
  query users($query: UserSearchInput!) {
    searchUsers(query: $query) {
      count
      rows {
        userUuid
        lastName
        firstName
      }
    }
  }
`

export const SEARCH_USERS = gql`
  query searchUsers($query: UserSearchInput!, $withDisabled: Boolean = false) {
    searchUsers(query: $query, withDisabled: $withDisabled) {
      count
      rows {
        abmMeta {
          businessUnit {
            label
          }
        }
        userUuid
        firstName
        lastName
        email
        enabled
        rights
        userTypes
        createdAt
        updatedAt
        mslMeta {
          mslMetaUuid
          businessUnitUuid
          businessUnit {
            label
          }
          zoneUuid
          zone {
            label
            businessUnit {
              label
            }
          }
        }
      }
    }
  }
`

export const SEARCH_ALL_USERS = gql`
  query searchUsers($query: UserSearchInput!) {
    searchUsers(query: $query) {
      count
      rows {
        userUuid
        firstName
        lastName
        email
        enabled
        rights
        userTypes
        mslMeta {
          mslMetaUuid
          zone {
            businessUnit {
              label
            }
          }
        }
      }
    }
  }
`

export const SEARCH_USERS_FOR_GATHERING = gql`
  query searchUsersForGathering(
    $gatheringUuid: GUID!
    $limit: Int
    $offset: Int
    $search: String
    $businessUnitUuids: [GUID!]
    $sort: JSONObject
  ) {
    searchUsersForGathering(
      gatheringUuid: $gatheringUuid
      limit: $limit
      offset: $offset
      search: $search
      businessUnitUuids: $businessUnitUuids
      sort: $sort
    ) {
      count
      rows {
        userUuid
        firstName
        lastName
        email
        userTypes
        abmMeta {
          businessUnit {
            externalId
            label
          }
        }
        mslMeta {
          zone {
            label
            businessUnit {
              externalId
              label
            }
          }
        }
      }
    }
  }
`
