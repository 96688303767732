<template>
  <div>
    <v-row>
      <v-col cols="3">
        <date-picker v-model="gathering.beginDate" label="Date de début" />
      </v-col>

      <v-col cols="3">
        <date-picker v-model="gathering.endDate" label="Date de fin" />
      </v-col>

      <v-col cols="3">
        <mybb-select v-model="eventType" :items="eventTypeItems" label="Type d'événement" />
      </v-col>

      <v-col cols="3">
        <mybb-select v-model="categoryVersion" :items="availablesVersions" label="Version de catégorie" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <mybb-text-field v-model="search" label="Recherche" />
      </v-col>
    </v-row>

    <div class="my-2">
      <mybb-text>
        Type d'événement : <mybb-text weight="bold">{{ libraryEventType }}</mybb-text>
      </mybb-text>
    </div>

    <v-row>
      <v-col cols="12">
        <v-data-table
          style="width: 100%;"
          :items="categories || []"
          :headers="headers"
          :search="search"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.color`]="{ value }">
            <v-chip :color="value" dark>
              {{ value }}
            </v-chip>
          </template>

          <template v-slot:[`item.eventTypes`]="{ value }">
            <mybb-text style="display: block;" class="my-1" v-for="type of value || []" :key="type">
              {{ type }}
            </mybb-text>
          </template>

          <template v-slot:[`item.tags`]="{ value }">
            <mybb-text style="display: block;" class="my-1" v-for="tag of value || []" :key="tag">
              {{ tag }}
            </mybb-text>
          </template>

          <template v-slot:[`item.modules`]="{ value }">
            <mybb-text style="display: block;" class="my-1" v-for="module of value || []" :key="module">
              {{ module }}
            </mybb-text>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Categories from 'mybb-categories'

import DatePicker from '../mybb/ui/DatePicker'

export default {
  name: 'DebugCategories',
  components: { DatePicker },
  data() {
    return {
      search: '',
      categoryVersion: Categories.latestVersion,
      gathering: {
        beginDate: new Date(),
        endDate: new Date(),
        congress: 'CONGRESS',
        biogenEvent: null
      }
    }
  },
  computed: {
    eventType: {
      get() {
        if (this.gathering.congress) return 'congress'
        if (this.gathering.biogenEvent) return 'standalone'
        return null
      },
      set(value) {
        if (value === 'congress') {
          this.gathering.congress = 'CONGRESS'
          this.gathering.biogenEvent = null
        } else if (value === 'standalone') {
          this.gathering.congress = null
          this.gathering.biogenEvent = 'STANDALONE'
        } else {
          this.gathering.congress = null
          this.gathering.biogenEvent = null
        }
      }
    },
    availablesVersions() {
      return [{ value: null, text: 'Aucune' }].concat(
        Categories.availablesVersions.map(version => ({
          value: version,
          text: version
        }))
      )
    },
    eventTypeItems() {
      return [
        { value: null, text: 'Aucun' },
        { value: 'congress', text: 'Congrès' },
        { value: 'standalone', text: 'Standalone' }
      ]
    },
    libraryEventType() {
      if (!this.eventType) return null

      return Categories.getEventTypeFromGathering(this.gathering)
    },
    categories() {
      const { beginDate, endDate, congress, biogenEvent } = this.gathering

      if (!beginDate || !endDate || (!congress && !biogenEvent) || !this.categoryVersion) return

      return Categories.forEventType(this.libraryEventType, this.categoryVersion)
    },
    headers() {
      return [
        { sortable: false, text: 'Label', value: 'label' },
        { sortable: false, text: 'Couleur', value: 'color' },
        { sortable: false, text: "Type d'event", value: 'eventTypes' },
        { sortable: false, text: 'Tags', value: 'tags' },
        { sortable: false, text: 'Modules', value: 'modules' }
      ]
    }
  }
}
</script>
