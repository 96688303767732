<template>
  <div>
    <v-row v-for="(link, index) in links" :key="`link-${index}`">
      <v-col md="8">
        <v-text-field
          v-model="link.url"
          :data-vv-name="'url' + index"
          :data-vv-as="$t('manage-dashboard.form.defaultUrl')"
          :error-messages="errors.collect('url' + index)"
          hide-details="auto"
          dense
          outlined
          v-validate="'startWithHttp|url'"
        />
      </v-col>
      <v-col md="4">
        <v-select
          :items="availableBU(link)"
          v-model="link.buUuids"
          multiple
          dense
          full-width
          hide-details="auto"
          outlined
          single-line
          :label="$t('manage-dashboard.form.bus')"
        />
      </v-col>
    </v-row>

    <v-row justify="center" v-if="availableBU().length && !hasEmptyLink">
      <v-btn @click="links.push({ buUuids: [], url: '' })" class="mt-2" color="blue" dark>{{
        $t('manage-dashboard.form.add-link')
      }}</v-btn>
    </v-row>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ApplicationLinkForm',

  inject: ['$validator'],

  props: {
    businessUnits: {
      type: Array,
      default: () => []
    },
    selectedBus: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      links: [],
      temporaryLink: null
    }
  },

  computed: {
    linksToValue() {
      return this.links
        .filter(link => link.url && link.buUuids.length)
        .reduce((acc, link) => {
          for (const bu of link.buUuids) {
            acc[bu] = link.url
          }

          return acc
        }, {})
    },
    hasEmptyLink() {
      return this.links.some(link => !link.url || !link.buUuids.length)
    }
  },
  methods: {
    setLinksFromValue() {
      const links = []

      Object.keys(this.value || {}).forEach(buUuid => {
        const url = this.value[buUuid]
        const linkIndex = links.findIndex(l => l.url === url)

        if (linkIndex < 0) {
          links.push({
            buUuids: [buUuid],
            url
          })
        } else {
          links[linkIndex].buUuids.push(buUuid)
        }
      })

      this.links = links
    },
    availableBU(link) {
      const linkIndex = link ? this.links.findIndex(l => l.url === link.url) : -1
      const otherLinks = this.links.filter((v, index) => index !== linkIndex)

      return this.businessUnits
        .filter(bu => this.selectedBus.includes(bu.businessUnitUuid))
        .filter(bu => !otherLinks.some(l => l.buUuids.includes(bu.businessUnitUuid)))
        .map(bu => ({ value: bu.businessUnitUuid, text: bu.label }))
    }
  },
  mounted() {
    this.setLinksFromValue()
  },
  watch: {
    links: {
      handler(val, old) {
        if (!old || (Array.isArray(old) && old.length === 0)) return

        this.$emit('input', this.linksToValue)
      },
      deep: true
    },
    selectedBus(value) {
      this.links = this.links
        .map(link => {
          const _link = _cloneDeep(link)

          _link.buUuids = _link.buUuids.filter(bu => value.includes(bu))

          return _link.buUuids.length ? _link : null
        })
        .filter(Boolean)
    }
  }
}
</script>
