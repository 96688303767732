import gql from 'graphql-tag'

export const GET_USER = gql`
  query user($uuid: GUID!) {
    user(userUuid: $uuid) {
      enabled
      firstName
      lastName
      email
      createdAt
      updatedAt
      allRights
      phone
      rights
      userTypes
      userUuid
      manager {
        userUuid
      }
      abmMeta {
        businessUnitUuid
      }
      mslMeta {
        mslMetaUuid
        businessUnitUuid
        businessUnit {
          label
        }
        zoneUuid
        zone {
          businessUnitUuid
          label
        }
      }
    }
  }
`

export const GET_USER_PROFIL = gql`
  query user($uuid: GUID!) {
    user(userUuid: $uuid) {
      firstName
      lastName
      email
      phone
      mslMeta {
        zone {
          label
        }
      }
    }
  }
`
