<template>
  <v-card>
    <v-card-text>
      <application-form
        v-model="app"
        :business-units="businessUnits"
        @close="$emit('close')"
        @submit="createApp"
        allow-full-edit
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ApplicationForm from './ApplicationForm'

export default {
  name: 'AppCreationDialog',
  components: { ApplicationForm },
  props: {
    businessUnits: {
      type: Array,
      required: false,
      default: () => []
    },
    place: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      app: {
        businessUnitUuids: [],
        buUrls: {},
        title: '',
        description: '',
        userTypes: [],
        url: '',
        subtitle: 'App Biogen' // Does not seems used anymore
      }
    }
  },
  methods: {
    createApp() {
      const app = Object.assign({}, this.app, { place: this.place })

      this.$emit('create', app)
    }
  }
}
</script>
