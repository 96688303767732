<template>
  <v-container>
    <v-card class="mb-2">
      <v-card-title>Typographies</v-card-title>
      <v-card-text>
        <div class="mb-2"><mybb-text size="20" weight="bold">TITRE - POP-IN</mybb-text></div>
        <div class="mb-2"><mybb-text size="16" weight="bold">TITRE - SECTION</mybb-text></div>
        <div class="mb-2"><mybb-text size="16" weight="semi-bold">TEXT - BOUTON</mybb-text></div>
        <div class="mb-2"><mybb-text weight="black">MENU PRINCIPAL - ÉVÈNEMENT / HCP</mybb-text></div>
        <div class="mb-2"><mybb-text weight="bold">SOUS-TITRE - SECTION / ENTRÉES PRINCIPALES TABLEAU</mybb-text></div>
        <div class="mb-2"><mybb-text weight="bold">ENTRÉE MENU ACTIVE</mybb-text></div>
        <div class="mb-2"><mybb-text>TEXTE - VALEUR/TABLEAU</mybb-text></div>
        <div class="mb-2"><mybb-text size="12" weight="semi-bold">TEXT - STATUS</mybb-text></div>
        <div class="mb-2"><mybb-text size="12" weight="black">ENTÊTE TABLEAU</mybb-text></div>
        <div class="mb-2"><mybb-text size="12">NOM LABELS/CHAMPS/PLACEHOLDERS</mybb-text></div>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>Text fields</v-card-title>
      <v-card-text>
        <date-picker label="Date picker" class="mb-2" />
        <time-picker label="Time picker" class="mb-2" />
        <mybb-text-field label="Basic" class="mb-2" />
        <mybb-text-field label="Basic in error" class="mb-2" error />
        <mybb-text-field label="Basic in error with message" class="mb-2" error-messages="This is an error message" />
        <mybb-text-field label="With icon" icon="mdi-map-marker" class="mb-2" />
        <mybb-text-field label="Error with icon" icon="mdi-map-marker" class="mb-2" error />
        <mybb-text-field
          label="Error with icon"
          icon="mdi-map-marker"
          class="mb-2"
          error-messages="This is an error message"
        />
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>Select fields</v-card-title>
      <v-card-text>
        <mybb-select-field label="Basic" :items="selectItems" class="mb-2" />
        <mybb-select-field label="Basic in error" :items="selectItems" error class="mb-2" />
        <mybb-select-field
          label="Basic in error with message"
          :items="selectItems"
          error-messages="This is an error message"
          class="mb-2"
        />
        <mybb-select-field label="With icon" :items="selectItems" icon="mdi-earth" class="mb-2" />
        <mybb-select-field label="With icon in error" :items="selectItems" error icon="mdi-earth" class="mb-2" />
        <mybb-select-field
          label="With icon in error with message"
          :items="selectItems"
          error-messages="This is an error message"
          icon="mdi-earth"
          class="mb-2"
        />
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>Auto completes</v-card-title>
      <v-card-text>
        <mybb-autocomplete label="Basic" :items="autocompleteItems" class="mb-2" />
        <mybb-autocomplete label="Basic in error" :items="autocompleteItems" class="mb-2" error />
        <mybb-autocomplete
          label="Basic in error with message"
          :items="autocompleteItems"
          class="mb-2"
          error-messages="This is an error message"
        />
        <mybb-autocomplete label="With icon" icon="mdi-map-marker" :items="autocompleteItems" class="mb-2" />
        <mybb-autocomplete
          label="Error with icon"
          :items="autocompleteItems"
          icon="mdi-map-marker"
          class="mb-2"
          error
        />
        <mybb-autocomplete
          label="Error with icon"
          :items="autocompleteItems"
          icon="mdi-map-marker"
          class="mb-2"
          error-messages="This is an error message"
        />
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>Text areas (Default to 5 rows)</v-card-title>
      <v-card-text>
        <mybb-textarea label="Basic 2 rows" :rows="2" class="mb-2" />
        <mybb-textarea label="Basic" class="mb-2" />
        <mybb-textarea label="Basic in error" class="mb-2" error />
        <mybb-textarea label="Basic in error with message" class="mb-2" error-messages="This is an error message" />
        <mybb-textarea label="With icon" icon="mdi-map-marker" class="mb-2" />
        <mybb-textarea label="Error with icon" icon="mdi-map-marker" class="mb-2" error />
        <mybb-textarea
          label="Error with icon"
          icon="mdi-map-marker"
          class="mb-2"
          error-messages="This is an error message"
        />
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>Buttons</v-card-title>
      <v-card-text>
        <div class="mb-4">
          <mybb-btn @click="onClick('Default')" class="mr-2">Default</mybb-btn>
          <mybb-btn @click="onClick('Success')" class="mr-2" color="mybb-success">Success</mybb-btn>
          <mybb-btn @click="onClick('Error')" class="mr-2" color="mybb-error">Error</mybb-btn>
          <mybb-btn @click="onClick('Default (dark)')" class="mr-2" color="mybb-primary">Default (primary)</mybb-btn>
          <mybb-btn @click="onClick('Grey')" class="mr-2" color="mybb-grey">Grey</mybb-btn>
        </div>
        <div class="mb-4">
          <mybb-btn @click="onClick('Default')" class="mr-2" inner-icon="mdi-pencil">Default</mybb-btn>
          <mybb-btn @click="onClick('Success')" class="mr-2" color="mybb-success" inner-icon="mdi-pencil"
            >Success</mybb-btn
          >
          <mybb-btn @click="onClick('Error')" class="mr-2" color="mybb-error" inner-icon="mdi-pencil">Error</mybb-btn>
          <mybb-btn @click="onClick('Default (dark)')" class="mr-2" color="mybb-primary" inner-icon="mdi-pencil"
            >Default (primary)</mybb-btn
          >
          <mybb-btn @click="onClick('Grey')" class="mr-2" color="mybb-grey" inner-icon="mdi-pencil">Grey</mybb-btn>
        </div>
        <div class="mb-4">
          <mybb-btn @click="onClick('Default')" class="mr-2" icon inner-icon="mdi-pencil"></mybb-btn>
          <mybb-btn
            @click="onClick('Success')"
            class="mr-2"
            icon
            color="mybb-success"
            inner-icon="mdi-pencil"
          ></mybb-btn>
          <mybb-btn @click="onClick('Error')" class="mr-2" icon color="mybb-error" inner-icon="mdi-pencil"></mybb-btn>
          <mybb-btn
            @click="onClick('Default (dark)')"
            icon
            class="mr-2"
            color="mybb-primary"
            inner-icon="mdi-pencil"
          ></mybb-btn>
          <mybb-btn @click="onClick('Grey')" class="mr-2" icon color="mybb-grey" inner-icon="mdi-pencil"></mybb-btn>
        </div>
        <div class="mb-4">
          <mybb-btn @click="onClick('Default')" class="mr-2" icon inner-icon="mdi-pencil"></mybb-btn>
          <mybb-btn
            @click="onClick('Success')"
            class="mr-2"
            icon
            background="mybb-success"
            inner-icon="mdi-pencil"
          ></mybb-btn>
          <mybb-btn
            @click="onClick('Error')"
            class="mr-2"
            icon
            background="mybb-error"
            inner-icon="mdi-pencil"
          ></mybb-btn>
          <mybb-btn
            @click="onClick('Default (dark)')"
            icon
            class="mr-2"
            background="mybb-primary"
            inner-icon="mdi-pencil"
          ></mybb-btn>
          <mybb-btn
            @click="onClick('Grey')"
            class="mr-2"
            icon
            background="mybb-grey"
            inner-icon="mdi-pencil"
          ></mybb-btn>
        </div>
      </v-card-text>
    </v-card>

    components: { TimePicker },
  </v-container>
</template>

<script>
import DatePicker from '@/components/mybb/ui/DatePicker.vue'
import TimePicker from '@/components/mybb/ui/TimePicker.vue'

export default {
  components: { DatePicker, TimePicker },
  name: 'DebugUI',
  data() {
    return {
      selectItems: [
        { value: 1, text: 'Item 1' },
        { value: 2, text: 'Item 2' },
        { value: 3, text: 'Item 3' },
        { value: 4, text: 'Item 4' },
        { value: 5, text: 'Item 5' }
      ],
      autocompleteItems: [
        { value: 1, text: 'David Bengsch' },
        { value: 2, text: 'Yannick Herrero' },
        { value: 3, text: 'Morgan Druesne' },
        { value: 4, text: 'Lukka Blois' },
        { value: 5, text: 'Damien Tivelet' }
      ]
    }
  },
  methods: {
    onClick(message) {
      // eslint-disable-next-line no-alert
      window.alert(message)
    }
  }
}
</script>
