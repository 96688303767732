<template>
  <form>
    <User v-model="form" mode="create" />
  </form>
</template>

<script>
import User from '@/components/admin/User.vue'
import { GET_ALL_ZONES } from '@/graphql/Zone/GetZones'
import { GET_MANAGERS } from '@/graphql/User/SearchUsers'

export default {
  $_veeValidate: {
    validator: 'new'
  },

  components: {
    User
  },

  data() {
    return {
      user: {
        userTypes: []
      },
      valid: true
    }
  },

  apollo: {
    managers: {
      query: GET_MANAGERS,
      variables() {
        return {
          userTypes: [
            this.$const.userType.ABM_MANAGER,
            this.$const.userType.MSL_MANAGER,
            this.$const.userType.DM,
            this.$const.userType.CP
          ]
        }
      },
      update: ({ searchUsers }) => searchUsers.rows
    },
    zones: {
      query: GET_ALL_ZONES
    }
  },

  computed: {
    form: {
      get() {
        return {
          managers: this.managers,
          user: this.user,
          zones: this.zones
        }
      },
      set(value) {
        if (!value || (value && !value.user)) return

        this.user = value.user
      }
    }
  },

  methods: {}
}
</script>

<style lang="scss">
.User {
  max-width: 1000px;
}
</style>
