<template>
  <v-container fluid class="pa-4">
    <v-card class="mb-4">
      <v-card-title>Internal user picker</v-card-title>
      <v-card-text>
        <v-switch label="Clearable" v-model="internalUser.clearable" />
        <v-switch label="Multiple" v-model="internalUser.multiple" />

        <v-row>
          <internal-user-picker
            v-model="internalUser.value"
            label="Internal user picker"
            :multiple="internalUser.multiple"
            :clearable="internalUser.clearable"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>Healthcare Professional picker</v-card-title>
      <v-card-text>
        <v-switch label="Clearable" v-model="hcpUser.clearable" />
        <v-switch label="Multiple" v-model="hcpUser.multiple" />

        <v-row>
          <health-care-professional-picker
            v-model="hcpUser.value"
            label="Healthcare professional picker"
            :multiple="hcpUser.multiple"
            :clearable="hcpUser.clearable"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title>
        Categories
      </v-card-title>
      <v-card-text>
        <debug-categories />
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>Activity orators (Published congress)</v-card-title>
      <v-card-text>
        <activity-orators v-model="activityOrators" gatheringUuid="1778a091-a417-5e56-9d3b-c64e4206f825" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import InternalUserPicker from '@/components/mybb/user/InternalUserPicker'
import HealthCareProfessionalPicker from '@/components/mybb/user/HealthCareProfessionalPicker'
import ActivityOrators from '@/components/mybb/activity/ActivityOrators'
import DebugCategories from '@/components/debug/DebugCategories'

export default {
  components: {
    InternalUserPicker,
    HealthCareProfessionalPicker,
    ActivityOrators,
    DebugCategories
  },
  name: 'Debug',
  data() {
    return {
      internalUser: {
        value: null,
        clearable: false,
        multiple: false
      },
      hcpUser: {
        value: null,
        clearable: false,
        multiple: false
      },
      activity: {
        gatheringUuid: '1778a091-a417-5e56-9d3b-c64e4206f825' // Published congress
      },
      activityOrators: [
        {
          healthCareProfessionalUuid: 'ee10efd9-3a74-54bd-ab8f-6de97eb94e5f'
        }
      ]
    }
  }
}
</script>
