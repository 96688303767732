<template>
  <v-container fluid class="UserList pa-0">
    <div class="toolbar mb-8 px-4 pt-2">
      <div class="font-weight-bold">{{ $t`search-user` }}</div>

      <v-row>
        <v-col cols="5" class="pt-0">
          <v-text-field v-model="searchText" clearable outlined dense hide-details append-icon="mdi-magnify" />
        </v-col>
        <v-col cols="2" class="pt-0">
          <v-select v-model="types" :items="selectUserTypes" :label="$t`type`" multiple hide-details dense outlined />
        </v-col>
      </v-row>
    </div>

    <v-card class="pa-5 mb-5">
      <v-data-table
        :headers="headers"
        :items="users.rows"
        :loading="loading"
        :server-items-length="users.count"
        :options.sync="dataOptions"
        :items-per-page="10"
        item-key="userUuid"
        :footer-props="{
          itemsPerPageOptions: [10, 15, 30]
        }"
      >
        <template v-slot:item.enabled="{ value }">
          <span class="UserList-status" :class="{ 'UserList-status--disable': !value }">
            {{ $t(`userList.status.${value}`) }}
          </span>
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip v-for="userType in item.userTypes" v-bind:key="item.userUuid + userType" color="admin-primary" dark>{{
            userType
          }}</v-chip>
        </template>
        <template v-slot:item.businessUnit="{ item }">
          {{
            $get(item, 'mslMeta.businessUnit.label') ||
              $get(item, 'mslMeta.zone.businessUnit.label') ||
              $get(item, 'abmMeta.businessUnit.label')
          }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon @click="updateUser(item)" class="small">mdi-pencil</v-icon>
        </template>
        <template v-slot:item.impersonificate="{ item }">
          <v-icon @click="impersonificate(item)">mdi-eye</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-btn
      v-if="hasRight($const.right.createUser)"
      color="admin-primary"
      :to="{ name: 'AdminCreateUser' }"
      fab
      dark
      elevation="3"
      class="add-button"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import CONST from '@/const/shared'
import { SEARCH_USERS } from '@/graphql/User/GetUsers'

export default {
  data() {
    return {
      dataOptions: {
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      displayFilters: 0,
      loadingCount: 0,
      searchText: '',
      selectUserTypes: Object.keys(CONST.userType)
        .map(key => ({
          text: key,
          value: CONST.userType[key]
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
      types: [],
      users: {
        count: 0,
        rows: []
      }
    }
  },

  apollo: {
    users: {
      debounce: 300,
      loadingKey: 'loadingCount',
      query: SEARCH_USERS,
      variables() {
        return {
          query: this.query,
          withDisabled: true
        }
      },
      update: data => data.searchUsers
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    headers() {
      const headers = [
        {
          text: this.$t('userList.statusLabel'),
          class: 'UserList--nowrap',
          align: 'center',
          width: '80px',
          value: 'enabled'
        },
        { text: this.$t`lastName`, class: 'UserList--nowrap', sortable: false, value: 'lastName' },
        { text: this.$t`firstName`, sortable: false, value: 'firstName' },
        { text: this.$t('userList.emailLabel'), sortable: false, value: 'email' },
        { text: this.$t`type`, sortable: false, align: 'center', width: '80px', value: 'type' },
        { text: this.$t`bu`, class: 'UserList--nowrap', value: 'businessUnit' },
        { text: this.$t`zone`, sortable: false, value: 'mslMeta.zone.label' }
      ]

      if (this.hasRight(this.$const.right.updateUser)) {
        headers.push({ text: '', align: 'start', width: '40px', sortable: false, value: 'action' })
      }

      if (this.hasRight(this.$const.right.impersonificate)) {
        headers.push({ text: '', align: 'center', width: '40px', sortable: false, value: 'impersonificate' })
      }

      return headers
    },
    loading() {
      return this.loadingCount > 0
    },
    query() {
      const query = {
        searchText: this.searchText,
        userTypes: this.types
      }

      if (this.dataOptions.sortBy && this.dataOptions.sortBy[0]) {
        query.sortBy = this.dataOptions.sortBy[0]
      }
      if (this.dataOptions.sortDesc && this.dataOptions.sortDesc[0]) {
        query.sortDir = 'desc'
      }

      if (this.dataOptions.page) {
        query.offset = (this.dataOptions.page - 1) * this.dataOptions.itemsPerPage
        query.limit = this.dataOptions.itemsPerPage
      }

      return query
    }
  },

  methods: {
    updateUser(item) {
      this.$router.push({ name: 'AdminUpdateUser', params: { uuid: item.userUuid } })
    },
    impersonificate(item) {
      this.$store.dispatch('IMPERSONIFICATE_USER', item.userUuid)
    }
  }
}
</script>

<style lang="scss">
.UserList {
  .expansion-content .v-select__selections {
    flex-wrap: nowrap;
  }

  .expansion-panels {
    :before {
      box-shadow: none;
    }
    .v-expansion-panel-header,
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
}
.UserList--nowrap {
  white-space: nowrap;
}
</style>

<style scoped lang="scss">
.UserList {
  .toolbar {
    position: sticky;
    background: white;
    box-shadow: 0 5px 7px #a0a0a033;
    border-bottom: 2px solid #a0a0a059;
  }

  .add-button {
    position: sticky;
    z-index: 8;
    bottom: 10px;
    right: 30px;
    left: calc(100% - 76px);
  }
}
.UserList-status {
  color: var(--v-success-base);
  font-weight: 600;

  &--disable {
    color: grey;
    font-weight: 400;
  }
}
</style>
