var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"DashboardApplication mb-4"},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"md":"1","justify":"center"}},[_c('v-icon',[_vm._v("mdi-drag-vertical-variant")])],1),_c('v-col',{attrs:{"md":"1","justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return on.click.apply(null, arguments)}}},'v-icon',attrs,false),[_vm._v(_vm._s(_vm.application.hidden ? 'mdi-eye-off-outline' : 'mdi-eye-outline'))])]}}]),model:{value:(_vm.visibilityModal),callback:function ($$v) {_vm.visibilityModal=$$v},expression:"visibilityModal"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('manage-dashboard.visibility-dialog.title')))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.application.hidden ? _vm.$t('manage-dashboard.visibility-dialog.show') : _vm.$t('manage-dashboard.visibility-dialog.hide'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","primary":"","color":"primary"},on:{"click":_vm.toggleVisibility}},[_vm._v(" "+_vm._s(_vm.$t('manage-dashboard.visibility-dialog.submit'))+" ")])],1)],1)],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('span',{staticClass:"subtitle-2 ml-2"},[_vm._v(_vm._s(_vm.application.title))])]),_c('v-col',{attrs:{"md":"2"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.roles.slice(0, 4).join(' - '))+" "),(_vm.roles.length > 4)?_c('v-tooltip',{attrs:{"bottom":"","transition":"scroll-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({},on),[_vm._v("+"+_vm._s(_vm.roles.length - 4))])]}}],null,false,4237109782)},_vm._l((_vm.roles),function(role){return _c('v-list-item',{key:role,attrs:{"dense":""}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(role))])])}),1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"md":"4","align":"center"}},[_vm._v(" "+_vm._s(_vm.readableBusinessUnits)+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"md":"2","align":"end"}},[_c('v-dialog',{attrs:{"max-width":"1110px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-pencil")])]}}]),model:{value:(_vm.creationDialog),callback:function ($$v) {_vm.creationDialog=$$v},expression:"creationDialog"}},[_c('v-card',[_c('v-card-text',[_c('application-form',{attrs:{"businessUnits":_vm.businessUnits,"application":_vm.application},on:{"change":function (app) { return _vm.$emit('change', app); },"close":function($event){_vm.creationDialog = false}}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }