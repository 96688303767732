import gql from 'graphql-tag'

export const UPDATE_USER = gql`
  mutation updateUser($user: UserUpdateInput!) {
    updateUser(user: $user) {
      firstName
      lastName
      email
      createdAt
      updatedAt
      mslMeta {
        mslMetaUuid
        zoneUuid
      }
    }
  }
`
